import React from 'react';

export const phrases = {
  acceptable: 'Acceptable',
  accumeasure: 'AccuMeasure',
  activitiesTitle: 'Activities',
  activity: 'Activity',
  activityFactor: 'Activity Factor',
  add: 'Add',
  addNewExercise: 'Add New Exercise',
  age: 'Age',
  athletes: 'Athletes',
  average: 'Average',
  baseBmr: 'Base BMR',
  basics: 'Basics',
  biometrics: 'Biometrics',
  bmiRange: 'BMI Range',
  bmiReferenceChart: 'BMI Reference Chart',
  bmiResults: 'Your BMI is: <strong>%{bmi}</strong>.',
  bmr: 'BMR',
  bodyFatPercentage: 'Body Fat Percentage',
  bodyFatReferenceChart: 'Body Fat Reference Chart',
  bodyFatResults: 'Your body fat is: <strong>%{bodyFat}</strong>.',
  cancel: 'Cancel',
  category: 'Category',
  copy: 'Copy',
  dailyCalories: 'Daily Calories',
  darkMode: 'Switch between dark and light mode (currently on %{theme} mode)',
  days: 'Days Per Week',
  daysLabel: 'Days',
  default: 'Default',
  delete: 'Delete',
  deleteProfile: 'Delete Profile',
  deleteProfileQuestion: 'Are you sure you want to delete the profile "%{name}"?',
  disclaimer: 'Disclaimer',
  duration: 'Duration (in minutes)',
  durationLabel: 'Duration',
  edit: 'Edit',
  editProfile: 'Edit %{name} Profile',
  endDate: 'Your end date is: <strong>%{end}</strong>',
  essentialFat: 'Essential Fat',
  exercise: 'Exercise',
  exercises: 'Exercises',
  extremelyActive: 'Extremely Active',
  fatLossResults: 'You can lose around <strong>%{pounds}</strong> lbs of fat in a week.',
  fitness: 'Fitness',
  gender: 'Gender',
  goal: 'Goal',
  goalBodyFatPercentage: 'Goal Body Fat Percentage',
  goalDate: 'Goal Date',
  goals: 'Goals',
  goalWeight: 'Goal Weight (lbs)',
  gradeOneObesity: 'Grade I Obesity',
  gradeThreeObesity: 'Grade III Obesity',
  gradeTwoObesity: 'Grade II Obesity',
  greaterThanEqual: '"%{label}", must be greater than or equal to %{min}',
  height: 'Height (in)',
  hipCircumference: 'Hip Circumference (in)',
  isRequired: '"%{label}" is required',
  labelIsRequired: '"Label" is required',
  lessThanEqual: '"%{label}" must be less than or equal to %{max}',
  lightlyActive: 'Lightly Active',
  measurements: 'Measurements',
  men: 'Men',
  metabolism: 'Metabolism',
  metsInfoLabel: 'METs: <strong>%{mets}</strong>',
  metsLabel: 'Mets',
  metMinutesDailyInfoLabel: 'MET minutes per day: <strong>%{metMinutes}</strong>',
  metMinutesWeeklyInfoLabel: 'MET minutes per week: <strong>%{metMinutes}</strong>',
  moderatelyActive: 'Moderately Active',
  mustBeNumber: '"%{label}" must be a number',
  name: 'Name',
  neckCircumference: 'Neck Circumference (in)',
  newProfile: 'New Profile',
  none: 'None',
  normalWeight: 'Normal Weight',
  obese: 'Obese',
  overweight: 'Overweight',
  percentLossPerWeek: 'Body Weight Percentage Loss Per Week',
  percentPerWeek: 'Percent Per Week',
  poundsLossPerWeek: 'Pounds Loss Per Week',
  poundsPerWeek: 'Pounds Per Week',
  preferredEquation: 'Preferred BMR Equation',
  print: 'Print Page',
  remove: 'Remove',
  resetProfile: 'Reset Profile',
  resetProfileQuestion: 'Are you sure you want to reset the default profile "%{name}"?',
  save: 'Save',
  saveExercise: 'Save Exercise',
  saveProfile: 'Save Profile',
  saveSettings: 'Save Settings',
  search: 'Search...',
  sedentary: 'Sedentary',
  selectProfiles: 'Select Profiles',
  settings: 'Settings',
  showStillNeedToBurn: 'Show "Still Need To Burn"',
  startDate: 'Your start date is: <strong>%{start}</strong>',
  startDateLabel: 'Start Date',
  startWeight: 'Start Weight (lbs)',
  submit: 'Submit',
  summary: 'Summary',
  fatLossSummaryLength:
    'It will take you <strong> %{weeks} weeks</strong> to reach your goal of <strong> %{goalBodyFat}%</strong> body fat. (%{days} days)',
  summaryLength:
    'It will take you <strong> %{weeks} weeks</strong> to reach your goal of <strong> %{goalWeight} lbs</strong>. (%{days} days)',
  toggleMenu: 'Toggle Menu',
  toggleSidebar: 'Toggle Sidebar',
  underweight: 'Underweight',
  valueIsRequired: '"Value" is required',
  veryActive: 'Very Active',
  waistCircumference: 'Waist Circumference (in)',
  weight: 'Weight (lbs)',
  wellness: 'Wellness',
  women: 'Women',
  accuMeasure: {
    bodyFatGoal: 'You need to lose %{diff} pounds of fat to reach your goal of %{pounds} body fat.', // 'You need to lose %{diff}% more body fat to reach your goal. (%{pounds} lbs)',
    pastGoal: 'You are %{diff}% past your goal!',
    atGoal: 'You are at your goal!',
    enterData: 'Please enter your gender and age',
    bodyFat: 'Your body fat percentage is %{bodyFat}%.',
    fatWeight: 'Your current body fat is %{fatWeight}.',
    leanWeight: 'Your current lean body mass is %{leanWeight}.',
    goalWeight:
      'Based on your body fat percentage goal of %{goalBodyFat}% body fat, if you kept your lean body mass your goal weight would be %{goalWeight}.',
    enterMeasurement: 'Enter the measurement from the caliper',
    fatFreeMassRule:
      'According to the "one-fourth fat free mass rule" you\'ll lose around 25% fat free mass per pound of weight loss. That would put your goal weight at %{goalWeight}.'
  },
  activities: {
    averageWeight: 'Calories you would burn on average: <strong>%{averageWeight}</strong>',
    currentMets: 'METs for this activity: <strong>%{mets}</strong>',
    currentWeight:
      'Calories you would burn at your current weight: <strong>%{currentWeight}</strong>',
    duration: 'Duration',
    goalWeight: 'Calories you would burn at your goal weight: <strong>%{goalWeight}</strong>',
    metsMinutes: 'MET minutes: <strong>%{metMinutes}</strong>',
    selectActivity: 'Select Activity',
    lightMets: 'Light METs: < 3',
    moderateMets: 'Moderate METs: 3-5.9',
    vigorousMets: 'Vigorous METs: > 5.9'
  },
  activityFactors: {
    activityDefinitions: 'Activity Factor Definitions',
    extremely: 'Extremely Active',
    extremelyDefinition: 'Hard daily exercise/sports and physical job or training',
    light: 'Lightly Active',
    lightDefinition: 'Light exercise/sports 1–3 days/week',
    moderate: 'Moderately Active',
    moderateDefinition: 'Moderate exercise/sports 3–5 days/week',
    sedentary: 'Sedentary',
    sedentaryDefinition: 'Desk job and little to no exercise',
    very: 'Very Active',
    veryDefinition: 'Hard exercise/sports 6–7 days/week'
  },
  bmiPage: {
    idealWeight:
      'Your ideal weight according to BMI charts is %{weight} lbs. With a healthy range of %{low} lbs to %{high} lbs.',
    lessThan: 'Less Than 18.5',
    over: 'Over 39.9'
  },
  bmrPage: {
    enterData: 'Please enter your gender, age, height, weight, and activity factor'
  },
  calculator: {
    averageBmr: 'Your average BMR across this timeline is <strong>%{averageBmr}</strong>',
    averageBurn:
      'In addition to your activity factor, on average, you need to burn an additional <strong>%{averageBurn} calories</strong> each day.',
    averageCalorieDeficit:
      'Your average calorie deficit is <strong>%{averageCalorieDeficit} calories</strong> a day',
    averageCalories:
      'Based on your average BMR and average calories burned each day, you should be eating <strong>%{averageCalories} calories</strong> a day',
    bmr: 'REMOVE ME',
    bmrMissingFields:
      'Please enter the following fields for the %{equation} calculators: %{fields}',
    bodyFat: 'Body Fat at Start of Week',
    caloriesBurned: 'Calories Burned Per Day',
    dailyBurned: 'Still Need To Burn',
    dailyDeficit:
      'You will need to have an average deficit of <strong>%{dailyDeficit}</strong> each day.',
    endDate: 'Your end date is: <strong>%{endDate}</strong>',
    enterData: 'There are no results to display',
    explanation:
      'Calories Burned Per Day includes both exercise and diet calories (based on "Daily Calories")',
    goalError: 'Your Daily Calories is set too high to reach your goal',
    fatLossGoalLength:
      'It will take you <strong>%{weeks} weeks</strong> to reach your goal of <strong>%{goalBodyFat}%</strong>. (%{days} days)',
    goalLength:
      'It will take you <strong>%{weeks} weeks</strong> to reach your goal of <strong>%{goalWeight} lbs</strong>. (%{days} days)',
    missingData: 'No results. Not sure why. Try again maybe.',
    missingFields: 'No results. Please enter the following fields: %{fields}',
    startDate: 'Your start date is: <strong>%{startDate}</strong>',
    summaryTitle: 'Summary',
    weeklyPoundLoss:
      'You will end up losing an average of <strong>%{weeklyPoundLoss} lbs</strong> per week.',
    weekOf: 'Week Of',
    weight: 'Weight at Start of Week',
    weightLost: 'Weight Lost',
    weightLostPerDays: ' (%{weight} lbs per day)'
  },
  disclaimerPage: {
    paragraphs:
      '<p>These tools were created for my personal use. I do not guarantee accuracy. I also do not take responsibility for decisions made based off the results of these tools. Use at your own risk.</p><p>Icons used are from <a href={`https://fontawesome.com/license`}>Font Awesome</a></p>'
  },
  errors: {
    activities: '"Activity" must be one of the following: %{names}',
    equation: '"Equation" must be one of the following: %{names}',
    gender: '"Gender" must be one of the following: male, female',
    genderRequired: '"Gender" is required',
    goalDateInvalid: '"Goal Date" is invalid',
    goalDateAfterToday: '"Goal Date" must be later than today',
    profileName: '"Profile Name" is required',
    startDateInvalid: '"Start Date" is invalid',
    age: 'Age',
    bodyFat: 'Body Fat',
    dailyCalories: 'Daily Calories',
    goalBodyFat: 'Goal Body Fat',
    goalWeight: 'Goal Weight',
    startWeight: 'Start Weight',
    height: 'Height',
    weeklyPercentLoss: 'Percent Per Week',
    weeklyPoundLoss: 'Pounds Per Week',
    weight: 'Weight'
  },
  exerciseForm: {
    addNewExercise: 'Add New',
    exercise: 'Exercise'
  },
  fatLossPage: {
    explanation:
      'According to <a target="_blank" href="https://www.strongerbyscience.com/realistic-training-goals/">Stronger By Science</a> (read the heading "How fast can I lose fat?") this is the amount of fat you can lose in a week.'
  },
  header: {
    accuMeasure: 'AccuMeasure',
    activities: 'Activities',
    activityFactors: 'Activity Factors',
    bmi: 'BMI',
    bmr: 'BMR',
    bodyFat: 'Body Fat',
    goal: 'Goal',
    calculators: 'Calculators',
    createNewProfile: 'Create New Profile',
    disclaimer: 'Disclaimer',
    exercise: 'Exercise',
    fatLoss: 'Fat Loss',
    goalDate: 'Goal Date',
    percent: 'Percent Per Week',
    pounds: 'Pounds Per Week',
    profiles: 'Profiles',
    selectProfiles: 'Select Profiles',
    settings: 'Settings',
    toggleMenu: 'Toggle Menu',
    toggleSidebar: 'Toggle Sidebar'
  },
  form: {
    activity: 'Activity Factor',
    age: 'Age',
    average: 'Average',
    averages: 'Averages',
    basics: 'Basics',
    bodyFat: 'Body Fat Percentage (if known)',
    calculations: 'Metabolism',
    copy: 'Copy',
    cunningham: 'Cunningham',
    current: 'Biometrics',
    currentGroup: 'Current Information',
    dailyCalories: 'Daily Calories',
    days: 'Days Per Week',
    default: 'Default',
    deleteProfile: 'Delete Profile',
    duration: 'Duration (in minutes)',
    eighth: 'Eighth',
    eleventh: 'Eleventh',
    equation: 'Preferred BMR Calculator',
    exercise: 'Exercise',
    extremely: 'Extremely Active - Hard daily exercise/sports and physical job or training',
    female: 'Female',
    fifteenth: 'Fifteenth',
    fifth: 'Fifth',
    first: 'First',
    fourteenth: 'Fourteenth',
    fourth: 'Fourth',
    gender: 'Gender',
    goalBodyFat: 'Goal Body Fat Percentage',
    goalDate: 'Goal Date',
    goalGroup: 'Goal Information',
    goals: 'Goals',
    goalWeight: 'Goal Weight (lbs)',
    harrisBenedict: 'Harris Benedict',
    height: 'Height (in)',
    katch: 'Katch McArdle',
    light: 'Lightly Active - Light exercise/sports 1–3 days/week',
    male: 'Male',
    measurements: 'Measurements',
    mifflin: 'Mifflin St. Jeor',
    moderate: 'Moderately Active - Moderate exercise/sports 3–5 days/week',
    name: 'Name',
    ninth: 'Ninth',
    none: 'None',
    remove: 'Remove',
    saveExercise: 'Save Exercise',
    second: 'Second',
    sedentary: 'Sedentary - Desk job and little to no exercise',
    seventeenth: 'Seventeenth',
    seventh: 'Seventh',
    sixteenth: 'Sixteenth',
    sixth: 'Sixth',
    startDate: 'Start Date',
    startWeight: 'Start Weight (lbs)',
    tenth: 'Tenth',
    theme: 'Theme',
    third: 'Third',
    thirteenth: 'Thirteenth',
    twelfth: 'Twelfth',
    very: 'Very Active - Hard exercise/sports 6–7 days/week',
    weeklyPercentLoss: 'Body Weight Percentage Lose Per Week',
    weeklyPoundLoss: 'Pounds Loss Per Week',
    weight: 'Weight (lbs)'
  },
  pages: {
    accuMeasure: {
      description: '',
      title: 'AccuMeasure'
    },
    activities: {
      description: '',
      title: 'Activities'
    },
    activityFactors: {
      description: '',
      title: 'Activity Factors'
    },
    bmi: {
      description: '',
      title: 'BMI'
    },
    bmr: {
      description: 'Calculate your BMR using up to four equations and see the average of them all',
      title: 'BMR'
    },
    bodyFat: {
      description: '',
      title: 'Body Fat'
    },
    disclaimer: {
      description: '',
      title: 'Disclaimer'
    },
    exercise: {
      description: '',
      title: 'Exercise'
    },
    fatLoss: {
      description: '',
      title: 'Fat Loss'
    },
    goal: {
      description: '',
      title: 'Goal'
    },
    goalDate: {
      description: '',
      title: 'Goal Date'
    },
    percent: {
      description: '',
      title: 'Percent Per Week'
    },
    pounds: {
      description: '',
      title: 'Pounds Per Week'
    },
    profiles: {
      description: '',
      title: 'Profiles'
    },
    settings: {
      description: '',
      title: 'Settings'
    }
  },
  profile: {
    activity: 'activity factor',
    age: 'age',
    bodyFat: 'body fat percentage',
    dailyCalories: 'calories per day',
    exercises: 'exercises',
    equation: 'BMR calculator',
    gender: 'gender',
    goalBodyFat: 'goal body fat percentage',
    goalDate: 'goal date',
    goalWeight: 'goal weight',
    startWeight: 'start weight',
    height: 'height',
    hip: 'hip',
    id: 'ID',
    isCurrent: 'current profile',
    isDefault: 'default profile',
    name: 'name',
    neck: 'neck',
    startDate: 'start date',
    theme: 'theme',
    waist: 'waist',
    weeklyPercentLoss: 'percent loss per week',
    weeklyPoundLoss: 'pounds loss per week',
    weight: 'weight'
  },
  sidebar: {
    current: 'Current Information',
    goal: 'Goal Information',
    weekly: 'Weekly Exercise Schedule'
  },
  suggestion: {
    currentFat: 'Current fat mass: %{currentFat}',
    currentLean: 'Current lean body mass: %{currentLean}',
    enterData:
      'To calculate this you need to provide your current weight, current body fat percentage and goal body fat percentage.',
    goal: 'Maintaining lean body mass your goal weight for the body fat percentage you provided is: %{goal}',
    title: 'How much should I weigh?'
  }
};
