import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/app.scss';

const Exercise = lazy(() => import('./pages/exercise'));
const Bmr = lazy(() => import('./pages/bmr'));
const Activities = lazy(() => import('./pages/activities'));
const Disclaimer = lazy(() => import('./pages/disclaimer'));
const ActivityFactors = lazy(() => import('./pages/activity-factors'));
const Percent = lazy(() => import('./pages/percent'));
const Pounds = lazy(() => import('./pages/pounds'));
const Goal = lazy(() => import('./pages/goal'));
const GoalDate = lazy(() => import('./pages/goal-date'));
const AccuMeasure = lazy(() => import('./pages/accumeasure'));
const Settings = lazy(() => import('./pages/settings'));
const Profile = lazy(() => import('./pages/profile'));
const BodyFat = lazy(() => import('./pages/body-fat'));
const Bmi = lazy(() => import('./pages/bmi'));
const FatLoss = lazy(() => import('./pages/fat-loss'));
const FatLossCalc = lazy(() => import('./pages/fat-loss-calc'));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route exact path={`/exercise`} element={<Exercise />} />
          <Route exact path={`/`} element={<Bmr />} />
          <Route exact path={`/activities`} element={<Activities />} />
          <Route exact path={`/disclaimer`} element={<Disclaimer />} />
          <Route exact path={`/activity-factors`} element={<ActivityFactors />} />
          <Route exact path={`/percentage`} element={<Percent />} />
          <Route exact path={`/pounds`} element={<Pounds />} />
          <Route exact path={`/goal`} element={<Goal />} />
          <Route exact path={`/goal-date`} element={<GoalDate />} />
          <Route exact path={`/accumeasure`} element={<AccuMeasure />} />
          <Route exact path={`/settings`} element={<Settings />} />
          <Route exact path={`/body-fat`} element={<BodyFat />} />
          <Route exact path={`/bmi`} element={<Bmi />} />
          <Route exact path={`/fat-loss`} element={<FatLoss />} />
          <Route exact path={`/fat-loss-calc`} element={<FatLossCalc />} />
          <Route exact path={`/profiles/new`} element={<Profile />} />
          <Route exact path={`/profiles/:id`} element={<Profile />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
